<template>
  <div class="open-door-result">
    <div class="yjkm-box" @click="openDoorOnClick">
      <img src="./img/yjlm-btn.png" />
    </div>
    <div class="kmjl-box">
      <img class="kmjl-btn" src="./img/kmjl_btn.png" @click="kmlsjlOnClick" />
      <div class="kmjl-text" @click="kmlsjlOnClick">历史开门记录</div>
    </div>
  </div>
</template>

<script>
import { handOpenDoorUrl } from './api.js';
import { mapState } from 'vuex';

export default {
  name: 'openDoorResult',
  data() {
    return {
      openDoorStatusFlag: 1 // 1未操作  2开门成功  3开门失败
    };
  },
  computed: {
    ...mapState(['userId', 'tenantId', 'communityId', 'houseId'])
  },
  created() {},
  methods: {
    openDoorOnClick() {
      this.$axios
        .get(handOpenDoorUrl, { params: { userId: this.userId } })
        .then(res => {
          if (res && res.code === 200) {
            this.toResult('1');
          } else {
            this.toResult('2');
          }
        })
        .catch(() => {
          this.toResult('2');
        });
    },
    toResult(value) {
      this.$router.push({
        name: 'openDoorResult',
        query: { value }
      });
    },
    kmlsjlOnClick() {
      this.$router.push({
        name: 'openDoorRecordList'
      });
    }
  }
};
</script>

<style lang="less" scoped>
.open-door-result {
  box-sizing: border-box;
  height: 100%;
  background-image: url('./img/yjkm_bg.png');
  background-repeat: no-repeat;
  background-position: 0% 0%;
  background-size: 100% auto;
  position: relative;
  .yjkm-box {
    box-sizing: border-box;
    width: 480px;
    height: 480px;
    position: absolute;
    left: 50%;
    top: 200px;
    transform: translate(-50%, 0);
    img {
      width: 100%;
      height: 100%;
    }
  }
  .kmjl-box {
    box-sizing: border-box;
    position: fixed;
    left: 50%;
    bottom: 30px;
    bottom: calc(30px + constant(safe-area-inset-bottom));
    bottom: calc(30px + env(safe-area-inset-bottom));
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .kmjl-btn {
      width: 100px;
      height: 100px;
    }
    .kmjl-text {
      font-size: 28px;
      line-height: 40px;
      color: #666666;
      margin-top: 10px;
    }
  }
}
</style>
